.secao{
    margin-top:20px
}

.painelGeral{
    min-height:400px;
    border:1px solid rgba(200,200,200,0.5);
    border-radius:5px;
    padding:10px;
    box-shadow: 5px 10px 8px rgba(200,200,200,0.5);
}

.painelTitulo{
    padding-left:10px;
    padding-right:10px;
}

.painelCorpo{
    margin-top:10px;
    padding-left:10px;
    padding-right:10px;
}

.users .tituloPainel{
    color:var(--color-green-medium);
    font-weight: bold;
}

.macrobloco{
    border-left:1px solid rgb(200,200,200);
}

.tituloPainel{
    padding-bottom:10px;
    color: gray;
}

.tituloPrincipal{
    display:flex;
    justify-content: center;
    align-items: center;
    height:30px
}

.users .tituloPainel .tituloPrincipal{
    justify-content: flex-start;
}

.subtitulo{
    font-size: 10px;
    font-weight: normal;
    margin-top:5px;
    display:flex;
    flex-direction: row;
    height:20px;
}

.subtitulo .coluna{
    display:flex;
    align-items: center;
    justify-content: center;
}

.coluna{
    width:25%;
}

.corpoPainel{
    height:80%;
    color: gray;
    padding-left:10px;
    font-size:10px
}

@media screen and (max-width: 990px){

    .bigView{
        display:none !important
    }

    .coluna{
        width:100%;
    }

}