.estatisticaPremioSection{
    width:100%;
    border:1px solid rgba(200,200,200,1);
    border-radius:5px;
    margin-top:15px;
    padding-left:15px;
    padding-right:15px;
    padding-bottom:15px;
    box-shadow: 5px 10px 8px rgba(200,200,200,0.5);
}

.estatisticaPremiosTrach{
    width:100%;
    height:50px;
    border-bottom:1px dashed rgb(200,200,200);
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: end;
    color:gray;
}

.estatisticaPremioBox{
    height:400px
}

#detalheTitulo{
    color:gray;
    font-size:15px
}

#detalheTitulo em{
    font-weight: bold;
}

.seta{
    font-size:15px !important;
    position:relative;
    top:1.5px
}

.linkNivel{
    margin-left:10px;
    margin-right:10px;
    cursor:pointer
}

.linkNivel :hover{
    font-weight: bold;
    font-size:20px;
}

.linkNivelAtivo{
    font-weight: bold;
    text-decoration: underline;
    color: var( --color-green-medium)
}

.hide{
    display:none
}

@media screen and (max-width: 576px){

    .estatisticaPremioSection{
        box-shadow: 5px 10px 8px rgba(200,200,200,0.0);
        margin-top:30px;
        padding-top:10px
    }

}