.form-check-input:focus {
    border-color: var(--color-green-light);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(102,205,170, 0.25);
}

.form-check-input:checked {
    background-color: var(--color-green-medium);
    border-color: var(--color-green-light);
}

.input-group{
    width:auto;
}

.btn-fim-input{
    display:flex;
}

.titulo-select{
    margin-bottom:10px
}

.form-control{
    color:gray
}

.textoLargo{
    height:100px;
    font-size:12px
}