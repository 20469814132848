.linhaCliente{
    height:40px;
    font-size:10px;
    padding-top:5px;
    padding-bottom:5px;
    border-radius:5px
}

.users{
    height:30px;
    display:flex;
    align-items: center;
    color: var(--color-green-medium);
    font-size:12px
}

.dados{
    height:30px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}

.coluna{
    display:flex;
    justify-content: center;
    align-items: center;
    width:25%;
    margin-left:5px;
    margin-right:5px;
    border:1px solid rgb(180,180,180);
    border-radius:5px;
    background-color:white;
    box-shadow: 1px 1px 1px 1px rgb(220,220,220);
    color:gray
}

.macrobloco{
    border-left:1px solid rgb(200,200,200)
}

.index{
    font-weight: bold;
    width:20px
}

.linewhite{
    background-color: white;
}

.linegray{
    background-color: rgba(200,200,200,0.3)
}

.itemOk{
    border: 1px solid var(--color-green-light);
    background-image: linear-gradient(to bottom, rgba(102,205,170,0.2) , rgba(0,139,139,0.2) );
    color:var(--color-green-medium)
}

.itemWarning{
    border: 1px solid rgba(139, 128, 0,0.4);
    background-image: linear-gradient(to bottom, rgba(255,255,0,.2) , rgba(255,255,0,.4) );
    color:rgb(139, 128, 0)
}

.itemBad{
    border: 1px solid rgba(255,127,80,.4);
    background-image: linear-gradient(to bottom, rgba(255,127,80,.1) , rgba(255,127,80,.3) );
    color:rgba(255,127,80,1)
}

@keyframes btnPisca {
    0% { opacity: 0.5; border:1px solid rgba(102,205,170); color: var(--color-green-medium)}
    100% { opacity: 1; }
}

.btnPisca {
    -webkit-animation: btnPisca .9s linear infinite;
    -moz-animation: btnPisca .9s linear infinite;
    -ms-animation: btnPisca .9s linear infinite;
    -o-animation: btnPisca .9s linear infinite;
    animation: btnPisca .9s linear infinite;
    cursor:pointer
 }

 .btn{
    border:1px solid rgba(102,205,170); color: var(--color-green-medium);
    cursor:pointer
 }

@media screen and (max-width: 990px){

    .bigView{
        display:none
    }

    .coluna{
        width:100%;
    }

    .nome{
        font-size:9px
    }

    .index{
        display:none
    }

}
