:root {
  --color-gray: rgb(100,100,100);
  --color-gray-light: rgb(200,200,200);
  --color-green-two: #5ec9cc;
  --color-green-light: rgb(102,205,170) ;
  --color-green-medium: rgb(0,139,139);
  --color-green-dark:rgb(0,94,94);
  --color-red: rgb(255,191,170);
}

@font-face {
  font-family: 'MuseoSans100'; /* Name your font family */
  src: url('/public/MuseoSans-100.otf'); /* Locate the .ttf file within your directory*/
}

.accordion {
  --bs-accordion-color: gray; 
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: gray;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: var(--color-green-light);
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(102, 205, 170, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--color-green-dark);
  --bs-accordion-active-bg: rgba(102, 205, 170, 0.25);
}

.AppNav{
  display:flex;
  height:100vh;
  direction: ltr;
}

.App{
  display:flex;
  flex-direction: column;
  height:100vh;
  padding-top:50px
}

.preLoader{
  height:100%;
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;
}

::-webkit-scrollbar {
  height: 5px !important;
  width: 5px !important;
}

::-webkit-scrollbar-track {
  background: rgba(200,200,200,0.0) !important;
}

::-webkit-scrollbar-thumb {
  background: rgba(200,200,200,0.8) !important;
  border-radius:3px;
}

main{
  display:flex;
  flex-direction:column;
  align-items:center; 
  padding-bottom:20px;
  padding-top:50px;
  width:100%;
  min-height: calc(100vh - 100px);
  height:100%;
  overflow-y: scroll;
}

body {
  margin: 0;
  font-family: 'MuseoSans100', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  border-bottom:1px solid var(--color-green-light);
  color: var(--color-green-light);
  font-size:24px;
  margin-top:40px;
  margin-bottom:20px;
  display:flex;
  align-items:center;
  line-height:normal;
  font-weight:normal;
  
}

.hide-pc{
  display:none !important;
}

@media screen and (max-width: 990px){

  main{
    padding-bottom:100px;
  }

  .AppNav{
    display:flex;
    flex-direction: column;
  }

}

@media screen and (max-width: 768px){

  .btn-cadastro{
    width:100% !important
  }

  .hide-cell{
    display:none;
  }

  .hide-pc{
    display:flex !important;
  }

}

.carousel-indicators [data-bs-target] {
  background-color:var(--color-green-medium) !important
}

.carousel-control-next-icon, .carousel-control-prev-icon{
  border-radius:50px;
  background-color: var(--color-green-light);
  border:1px solid var(--color-green-medium)
}

