.video-responsive {
    width:100%;
    margin-top:10px;
    border:1px solid rgb(220,220,220);
    padding:10px
}
  
.video-responsive iframe {
  height: 100%;
  width: 100%;
  border:1px solid gray
}

.youtubeVideo{
  border-radius:5px
}