.opcoesOpItemBox{
    width:100%;
    border:1px solid rgb(200,200,200);
    background-color: white;
    height:70px;
    padding:10px;
    border-radius:5px;
    display:flex;
    flex-direction: row;
    margin-bottom:15px;
}

.shortList{
    border: 1px solid var(--color-green-medium);
    background-image:linear-gradient(to bottom, rgba(102,205,170,0.1) , rgba(0,139,139,0.1) );
}

.box-shadow{
    box-shadow: 5px 10px 8px rgba(200,200,200,0.5);
}


.checkOpcao{
    width:40px;
    height:100%;
    display:flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-right:10px
}

.addOpList{
    color:var(--color-green-medium);
    cursor:pointer
}

.itemcolor{
    color:var(--color-green-medium);
}

.rankingOp{
    color:var(--color-green-medium);
    font-size:25px;
    font-style: italic;
}

.linhaOpcao{
    width:100%;
    height:100%;
}

.smallView{

}

.opcoesOpData{
    height:30px;
    border-bottom: 1px solid rgb(200,200,200);
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    font-size:11px;
    align-items: center;
}

.ticker{
    color: var(--color-green-medium);
    font-weight: bold;
    font-size: 13px;
    width:70px
}

.preco{
    font-weight: bold;
    font-size:13px
}

.opdata{
    color:gray
}

.opcoesOpAcaoData{
    height:20px;
    display:flex;
    flex-direction: row;
    font-size:10px;
    align-items:center;
    margin-top:5px;
    color:gray
}

.acao{
    font-weight: bold;
    margin-right:10px
}

.acaodata{
    margin-right:10px
}

.modalForm :first-child .modal-content{
    width: 1200px;
    height: 500px;
}

.modalForm .modal-dialog{
    max-width: 1200px;
}

@media screen and (max-width: 990px){

    .bigView{
        display:none
    }

}