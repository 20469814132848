.corpo{
    width:100%;
    height:100%;
    display:flex;
    flex-direction:row
}

.secao1{
    display:flex;
    border-radius:5px;
    height:100%;
    flex-direction: column;
    width:40%
}

.notVencimento{
    height:200px;
    border:1px solid var(--color-green-medium);
    border-radius:10px;
    background-image:linear-gradient(to bottom, rgb(102,205,170) , rgb(0,139,139) );
    display:flex;
    flex-direction: column;
}

.notNominal{
    color:white;
    position:relative;
    height:0px;
    display:flex;
    justify-content: end;
    padding-right:10px;
    padding-top:0px
}

.mensagem{
    color:gray;
    font-size:9px;
    position:relative;
    top:10px;
    height:0px;
    display:flex;
    padding-left:10px;
    padding-top:0px
}

.notTipo{
    margin-top:10px;
    height:115px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}

.notTipoItem{
    height:100%;
    width:31%;
    border: 1px solid var(--color-green-light);
    border-radius:10px;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding:20px;
    cursor:pointer
}

.notTipoItemDestaque{
    background-image:linear-gradient(to bottom right, rgba(200,200,200,0.3) , rgba(200,200,200,0) );
    font-size:20px;
    padding:10px;
    width:100%
}

.notTipoItemOculto{
    color:rgba(200,200,200,0.6) !important;
    border:1px solid rgba(200,200,200,0.3);
    display:none !important
}

.seco{
    color: var(--color-green-dark);
}

.longa{
    color: var(--color-green-medium);
}

.trava{
    color: var(--color-green-light);
}

.titulo{
    font-weight: bold;
}

.secao2{
    display:flex;
    flex-direction: column;
    width:30%;
    padding-left:10px
}

.summary{
    margin-top:10px;
    height:115px;
    border-radius:10px;
    display:flex;
    flex-direction: row;
    background-image:linear-gradient(to left, rgba(102,205,170,1.0) , white );
    border:1px solid var(--color-green-light)
}

.total{
    width:50%;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    border-radius:20px;
    border-top-right-radius:100px;
    border-bottom-right-radius:100px;
    border-right: 1px solid white;
    background-color:white;
    color: var(--color-green-medium)
}

.ponderado{
    width:50%;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    color:white
}

.distOTM{
    height:200px;
    border:1px solid var(--color-green-light);
    border-radius:10px;
    padding-left:5px
}

.secao3{
    display:flex;
    flex-direction: row;
    width:30%;
    padding-left:10px
}

.listaAcoes{
    width:100%;
    height:325px;
    border:1px solid var(--color-green-light);
    border-bottom:5px solid var(--color-green-light);
    border-radius:5px;
    padding:10px
}

.listaAcoesCorpo{
    width:100%;
    overflow-y: scroll;
    max-height:305px
}

.listaAcoesTitulo{
    width:100%;
    height:40px;
    padding:5px;
    border-bottom:1px dashed rgb(200,200,200)
}

.linhaAcoes{
    display:flex;
    flex-direction:row;
    width:100%;
}

.linhaAcoes .items{
    width:33%;
    height:30px;
    margin-left:5px;
    border:1px solid gray;
    border-radius:5px;
    display:flex;
    justify-content: center;
    align-items: center;
    color:gray;
    font-size:12px
}

.itemAcao{
    cursor:pointer
}

.linhaAcoes .items:first-child{
    font-weight:bold;
    color:var(--color-green-medium);
    background-color: rgba(102,205,170,0.3);
    font-size:14px
}

.listaOpcoes{
    padding-right:20px;
    width:100%;
}

.linhaOpcoes{
    display:flex;
    flex-direction:row;
    width:100%;
    font-size:11px;
    height:25px;
    border-bottom: 1px dashed rgb(200,200,200,1);
    padding-bottom:5px;
    padding-top:5px;
    margin-bottom:5px
}


.linhaOpcoes  .items{
    width:33%;
    padding-left:5px;
    border-left:0px solid rgb(200,200,200)
}

.linhaOpcoes .items:first-child{
    padding-left:0px;
    border-left:0px solid rgb(200,200,200)
}

.secaoReset{
    position:absolute;
    right:100px;
    bottom:-54px
}

.botaoReset{
    border-radius:5px;
    width:80px;
    height:36px;
    border:1px solid red;
    display:flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px rgba(200,200,200,0.6);
}