.resumoRepository{
    padding-right:0px !important;
}

/*.resumoBody{
    display:flex;
    flex-direction:row;
    height:100%
}*/

.box{
    border:1px solid var(--color-green-light);
    height:100%;
    border-radius:5px
}

.bridge{
    /*width:78%;*/
    /*margin-left: 2%;*/
    height:300px;
    padding-left: calc(var(--bs-gutter-x)* .5) !important;
    padding-right:0px
}

.bridgebox{
    height:100%
}

.indexes{
    /*width:20%;*/
    display:flex;
    flex-direction: column;
    height:300px;
    margin-bottom:15px;
    padding-right:0px !important
}

.indexesTitulo{
    font-size:20px;
    color: var(--color-green-light);
    font-weight: bold;
    margin-bottom:10px
}

.indexesRent{
    margin-bottom:10px;
    height:50px
}

.indexesAcoes{
    padding:5px;
    padding-top:0px
}

.rentabilidade{
    border-radius:10px;
    height:40px;
    display:flex;
    flex-direction:row;
    padding:10px
}

.rent{
    width:50%;
    height:100%;
    display:flex;
    justify-content: center;
    align-items:end;
    border-right:1px solid rgb(200,200,200);
    font-weight: bold;
    color:var(--color-green-medium)
}

.rentCDI{
    width:50%;
    height:100%;
    display:flex;
    justify-content: center;
    align-items:end;
    font-weight: bold;
    color:rgba(0,139,139,0.7)
}

.det{
    font-size:10px;
    font-style: italic;
    margin-left:5px;
    font-weight: lighter;
}

.acoes{
    height:285px;
    margin-top:5px;
}

.listaAcoes{
    height:235px;
    margin-top:5px;
    padding-right:4px;
    padding-left:4px;
    overflow-y: scroll;
}

.linhaAcao{
    display:flex;
    flex-direction:row;
    width:100%;
    height:15%;
    padding-left:10px;
    border-bottom:1px solid rgb(200,200,200);
    border-style: dashed;
    font-size:12px;
    align-items: center;
    font-weight:bold
}

.linhaAcaoQueda{
    color:var(--color-red)
}

.linhaAcaoSubida{
    color:var(--color-green-light)
}

.linhaAcaoPercentual{
    display:flex;
    justify-content: center;
    font-size:10px;
}

.linhaAcaoPercentualTitulo{
    display:flex;
    justify-content: center;
}

.linhaAcaoValor{
    font-size:10px;
    font-weight: lighter;
}

.linhaAcaoTitulo{
    display:flex;
    flex-direction:row;
    width:100%;
    height:15%;
    padding-left:10px;
    color:white;
    background-image:linear-gradient(to top right, rgba(0,139,139,.5) , rgba(0,139,139,.7) );
    border-radius:3px;
    font-size:12px;
    align-items: center;
}

.linhaAcao:last-child{
    border:0px solid white
}

.linhaAcaoBox{
    width:33%;
}

.seta{
    font-weight: bold;
    font-size:12px
}