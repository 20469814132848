.secaoAcao{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
    height:30px;
    align-items: center;
}

.preloaderBox{
    border:1px solid rgb(200,200,200);
    border-radius:10px;
    margin-top:10px;
    display:flex;
    justify-content: center;
    align-items: center;
    height:300px
}

.filtros{
    margin-bottom:20px
}

.filtroVencimentoBox{
    border:1px solid rgb(200,200,200);
    border-radius:10px;
    margin-top:10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:10px;
}

.vencimentoFiltro{
    color: var(--color-green-medium);
    font-weight: bold;
    margin-left:0px
}

.listaDeVencimentos{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap:wrap;
    margin-top: 10px;
}

.diaVencimento{
    height:50px;
    border:1px solid var(--color-green-medium);
    color: var(--color-green-medium);
    margin-left:10px;
    margin-right:10px;
    margin-bottom:10px;
    min-width:80px;
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius:5px;
    font-size:11px;
    cursor:pointer
}

.diaVencimentoAtivo{
    background-image:linear-gradient(to bottom, rgb(102,205,170) , rgb(0,139,139) );
    box-shadow: 5px 10px 8px rgba(200,200,200,0.5);
    color:white;
    font-weight: bold;
}

.nomeAcao{
    font-weight: bold;
    color: var(--color-green-medium);
    width:30%;
}

.precoAcao{
    width:30%;
    border-left:1px solid rgb(200,200,200);
    padding-left:2cqb
}

.notAcao{
    width:30%;
    border-left:1px solid rgb(200,200,200);
    padding-left:20px
}

.semaforo{
    width:40%;
    height:30px;
    padding-left:0px;
    display:flex;
    flex-direction: row;
    font-size:11px
}

.itemSemaforo{
    width:30px;
    margin-left:5px;
    margin-bottom:5px;
    border-radius:5px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.overflow{
    overflow-x: auto;
}

.table{
    width:100%
}

.tableHead{
    background: linear-gradient(to bottom, white, white);
    border-bottom:1px solid var(--color-green-medium);
}

.tableTitle{
    color: var(--color-green-medium);
    font-size:11px;
    min-width: 170px;
}

.tableTitleContent{
    display:flex;
    flex-direction: column;
}

.tableTitleDetail{
    font-style: italic;
    font-size:10px
}

.tableNomeTitle{
    position:sticky;
    left:0px;
    background-color:white !important;
    min-width: 100px;
    font-weight: bold;
}

.tableCCobertaTitle{
    left:0px;
    color: var(--color-gray);
    background-color:rgba(200,200,200,0.3) !important;
    border-top-right-radius:10px;
    border-top-left-radius:10px;
    min-width: 200px;
    font-weight: bold;
    border-right:2px solid white
}

.tableCliente{
    color: var(--color-green-medium);
    font-size:11px;
    position:sticky;
    left:0px;
    background-color:white !important;
    min-width: 100px;
    font-weight: bold;
}

.tableClienteCCoberta{
    color: var(--color-gray);
    font-size:11px; 
    left:0px;
    background-color:rgba(200,200,200,0.3) !important;
    border-right:2px solid white;
    min-width:200px
}

.tableCoberta{
    font-style: italic;
    font-size:8px;
    width:10% !important;   
}

.tableValor{
    color:gray;
    font-size:11px;
    border-right:2px solid white
    
}

.tableValorInteiro{
    width:100%;
    display:flex;
    flex-direction: row;
}

.tableValorParte{
    width:30%;
    text-wrap: nowrap;
    overflow:hidden;
    font-size:9px;
}

.tableValorCallCoberta{
    width:25%;
    text-wrap: nowrap;
    overflow:hidden;
}

.itemBad{
    background-image: linear-gradient(to bottom, rgba(255,127,80,.1) , rgba(255,127,80,.3) );
    color:rgba(255,127,80,1)
}

.itemWarning{
    background-image: linear-gradient(to bottom, rgba(255,255,0,.2) , rgba(255,255,0,.4) );
    color:rgb(139, 128, 0)
}

.itemOk{
    background-image: linear-gradient(to bottom, rgba(102,205,170,0.2) , rgba(0,139,139,0.2) );
    color:var(--color-green-medium)
}

.boxitemBad{
    border: 1px solid rgba(255,127,80,.4);
    background-image: linear-gradient(to bottom, rgba(255,127,80,.1) , rgba(255,127,80,.3) );
    color:rgba(255,127,80,1)
}

.boxitemWarning{
    border: 1px solid rgba(139, 128, 0,0.4);
    background-image: linear-gradient(to bottom, rgba(255,255,0,.2) , rgba(255,255,0,.4) );
    color:rgb(139, 128, 0)
}

.boxitemOk{
    border: 1px solid var(--color-green-light);
    background-image: linear-gradient(to bottom, rgba(102,205,170,0.2) , rgba(0,139,139,0.2) );
    color:var(--color-green-medium)
}

.commentsBox{
    display:flex;
    flex-direction: column;
    width:100%;
    margin-top:20px;
    height:170px;
    border:0px solid rgb(200,200,200);
    border-radius:10px;
    padding-top:10px;
    margin-bottom:20px
}

.commentTitle{
    font-size:11px;
    margin-bottom:5px;
}

.comentariosGeraisTitulo{
    font-weight: bold;
    color:var(--color-green-medium);
    margin-bottom:-10px;
    margin-left:20px
}

.commentInput{
    margin-bottom:5px
}

.commentInputBtnBox{
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: end;
}

.commentInputButton{
    position:relative;
    bottom:60px;
    right:10px
}



@media screen and (max-width: 768px){
  
    .hide-mid{
      display:none;
    }
  
  }