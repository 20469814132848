.telaLogin{
    height:100%;
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
}

.secaoLogin{
    width:100%;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;
}

.login{
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;
}

.titulo-login{
    border-bottom:0px !important;
    font-size:30px;
    color:var(--color-green-medium);
    margin-top:0px;
    box-shadow: none !important;

}

.login-box{
    display:flex;
    flex-direction:column;
    justify-content:center
}

.btn-login{
    width:100%;
}

.login-esqueceu-senha{
    text-align:center;
    margin-top:10px;
}

.esqueceu-senha-clique{
    color: var(--color-green-medium);
}

.esqueceu-senha-clique:hover {
    color: var(--color-green-light);
    font-weight:bold;
    cursor:pointer
}
