.footerLogin{
    background-image:linear-gradient(to bottom, rgb(102,205,170) , rgb(0,139,139) );
    height:50px;
    color:white;
}

.footerLogado{
    background-image:linear-gradient(to bottom, rgb(102,205,170) , rgb(0,139,139) );
    height:50px;
    color:white;
}

.footerCellStyle{
    display:none
}

footer h4{
    font-size:15px;
    font-weight:normal;
    padding-left:15px;
}

@media screen and (max-width: 990px){

    .footerLogado{
        background-image:linear-gradient(to bottom, rgba(102,205,170,0) , rgba(250,250,250,1) );
        height:80px;
        color:white;
        position:fixed;
        z-index:100000;
        display:flex;
        align-items:flex-start;
        bottom:0px;
        width:100%;
        padding-left:15px;
        padding-right:15px
    }

    .footerCellStyle{
        height:50px;
        width:100%;
        display:flex;
        align-items:center;
        border-radius:30px;
        background-image:linear-gradient(to bottom, rgb(102,205,170) , rgb(0,139,139) );
    }

    .box-indice-footer{
        height:80%;
        width:80%;
        display:flex;
        align-items:center;
        justify-content:center;
    }

    .nav{
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        width:100%;
        flex-wrap:nowrap
    }

    .nav-link {
        margin-left:0px;
        display:flex;
        align-items:center;
    }

    .nav .active{
        text-decoration: none !important;
        color:var(--color-green-light) !important;
        width:100%;
        border:1px solid white;
        display:flex;
        justify-content: center;
        border-radius:50px;
        background-image:linear-gradient(to top, rgb(194, 235, 221) , rgb(250, 250, 250) );
    }

}