.formEditOp{
    display:flex;
    flex-direction: row;
}

.calculadoraBox{
    width:20%;
    height:330px;
    border:1px solid var(--color-green-light);
    border-radius:10px;
    padding:10px;
    color:gray;
    margin-right:15px;
    box-shadow: 4px 2px 2px 2px rgba(200,200,200,0.5)
}

.calculadoraTitulo{
    height:40px;
    background-image:linear-gradient(to bottom, rgba(102,205,170,0.2) , rgba(0,139,139,0.2) );
    color: var(--color-green-medium);
    border-radius:5px;
    font-weight:bold;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom:15px
}

.camposCalculadora{
    color: var(--color-green-medium)
}

.calculadoraResultadoMensal{
    height:80px;
    width:100%;
    border:1px solid var(--color-green-light);
    border-radius:5px;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:40px;
    color: var(--color-green-light);
    padding:10px;
    background-image:linear-gradient(to bottom right, rgba(200,200,200,0.1) , rgba(200,200,200,0.4) );
}

.opcaoResultMensalTitulo{
    font-size:12px;
    color:gray;
}

.opcaoDetalheBox{
    width:20%;
    border:1px solid rgb(200,200,200);
    border-radius:10px;
    margin-right:15px;
    display:flex;
    flex-direction: column;
    padding:10px;
    box-shadow: 4px 2px 2px 2px rgba(200,200,200,0.5)
}

.opcaoDetalheBox .opcaoDetalheGregas{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}

.opcaoDetalheBox .opcaoDetalheGregas .opcaoGregasDet{
    width:30%;
    border:1px solid var(--color-green-medium);
    border-radius:5px;
    background-image:linear-gradient(to bottom, rgba(102,205,170,0.2) , rgba(0,139,139,0.2) );
    height:40px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-green-medium);
    font-size: 14px;
}

.opcaoDetalheSubTitulo{
    font-size: 12px;
    color: var(--color-green-medium);
    font-weight: bold;
    margin-bottom:5px;
    margin-top:5px
}

.opcaoGregasLetra{
    font-size: 12px;
}

.opcaoDetalheBox .opcaoDetalheVolatilidade{
    margin-top:10px
}

.opcaoVol{
    display:flex;
    flex-direction: row;
    border:1px solid var(--color-green-medium);
    border-radius:5px;
    height:50px;
    background-image:linear-gradient(to bottom, rgba(102,205,170,0.2) , rgba(0,139,139,0.2) );
}

.opcaoVolImp{
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: white;
    width:50%;
    display:flex;
    align-items: center;
    justify-content: center;
    font-size:13px;
    color:gray;
}

.opcaoVolIVR{
    color:var(--color-green-medium);
    font-size:13px;
    display:flex;
    align-items: center;
    justify-content: center;
    width:50%
}

.opcaoDetalhePrecoAtivo{
    display:flex;
    flex-direction: row;
    height:120px;
    border:1px solid var(--color-green-medium);
    border-radius:5px;
    padding-top:30px;
    padding-bottom:30px;
}

.opcaoDetalhePrecoAtivoPreco{
    width:33%;
    border-right:1px dashed rgb(200,200,200);
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--color-green-medium);
    font-size:13px
}

.opcaoDetalhePrecoAtivoStrike{
    width:33%;
    border-right:1px dashed rgb(200,200,200);
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--color-green-light);
    font-size:13px
}

.opcaoDetalhePrecoAtivoPercentual{
    width:33%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    text-align: center;
    color:gray;
    font-size:13px
}

.destaquePrecoOpcao{
    font-weight: bold;
    font-size:16px
}

.opcaoVolPutsCalls{
    display:flex;
    flex-direction: row;
    margin-top:15px;
    height:120px;
}

.opcaoVolPuts{
    width:50%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size:12px;
    color:gray
}

.opcaoVolPuts em{
    font: size 15px;
    font-weight: bold;
}

.opcaoVolPuts .opcaoVolPutsTitulo{
    color:var(--color-green-light);
    font-weight: bold;
    font-size:17px
}

.opcaoVolPuts .opcaoVolCallsTitulo{
    color:var(--color-green-dark);
    font-weight: bold;
    font-size:17px
}


.acaoBox{
    width:60%;
    border:1px solid rgb(200,200,200);
    border-radius:10px;
    padding:10px;
    display:flex;
    flex-direction:column;
    box-shadow: 4px 2px 2px 2px rgba(200,200,200,0.5)
}

.acaoBox .tituloAcao{
    color: var(--color-green-medium);
    font-weight: bold
}

.graficoAcao{
    height: 260px;
}

.tendenciaAcao{
    color:gray;
    font-size:13px;
    margin-top:7px
}

.tendenciaAcao em{
    font-weight: bold;
    color: var(--color-green-medium);
    margin-right:15px
}

.comentariosGraficoAcao{
    display:flex;
    flex-direction: row;
}