.react-calendar__navigation{
    border-radius:5px;
    margin-top:5px;
    font-size:12px;
}

.react-calendar__navigation__arrow{
    color: white;
    background-color: var(--color-green-light) !important;
    font-size:30px;
    border-radius:5px;
    margin:2px !important;
    cursor: pointer;
}

.react-calendar__navigation__arrow:hover{
    background-color: var(--color-green-medium) !important;
}

.react-calendar__navigation__label{
    border-radius:5px;
    margin:2px !important
}

.react-calendar__navigation__label:hover{
    border:1px solid gray
}

.react-calendar__navigation__label__labelText{
    color:gray;
}

.react-calendar__month-view__weekdays{
    border-bottom:1px solid var(--color-green-medium);
    color: var(--color-green-medium);
    font-size:12px;
    margin-bottom:5px;
}

abbr{
    text-decoration: none !important;
}

.react-calendar__tile{
    border-radius:100px;
    background-color:white !important
}

.react-calendar__tile--now{
    border-radius:100px;
    background-color: var(--color-green-light);
    opacity: 0.5;
}

.react-calendar__tile--active{
    border-radius:100px;
    background-color: var(--color-green-medium);
    color:white
}

.react-calendar__tile--range{
    border-radius:100px;
    background-color: var(--color-green-medium) !important;
    color:white
}