#chartContainer{
    height:100%;
    width:100%;
}

.carteiraDrillDown{
    height:290px;
    width:100%;
    margin-top:10px;
    display:flex;
    flex-direction:column;
}

.drillDownTitle{
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: end;
    height:35px;
    font-size:12px;
}

.tituloGrafDrillDown{
    color:gray;
    width:100%;
    font-size: 15px;
    padding-left:10px;
    display:flex;
    align-items: center;
}

.btnVoltaDrilldown{
    height:35px;
    width:100px;
    font-size:15px;
    cursor: pointer;
    text-align: center;
    display:flex;
    justify-content: center;
    align-items: center;
    color:var(--color-green-medium);
    border:1px solid var(--color-green-medium);
    border-radius:5px;
    background-image:linear-gradient(to bottom, rgba(102,205,170,0.5) , rgba(0,139,139,0.5) );;
}

.invisible {
    display: none;
}