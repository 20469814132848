.tela{
    width:100%;
}

.hide{
    display:none
}

.dataAtualizacao{
    margin-bottom:10px;
    margin-top:15px;
    font-style: italic;
    color:gray;
    font-size: 12px;
}

.dataAtualizacao em{
    font-weight: bold;
    color:var(--color-green-medium);
}

.tituloLista{
    height:30px;
    color:gray
}

.listaOpcoes{
    border-radius:5px;
    border:1px solid rgba(200,200,200,0.7);
    background-color:rgba(200,200,200,0.1);
    height: 400px;
    padding:10px;
    overflow-y: scroll;
}

.loaderOpcoes{
    border-radius:5px;
    border:1px solid rgba(200,200,200,0.7);
    background-color:rgba(200,200,200,0.1);
    height: 400px;
    padding:10px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.listaOpcoes li::marker{
    font-size:0px
}