.cardsRepository{
    width:100%;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    height:200px;
    overflow-y: scroll;
    border-bottom:1px solid rgba(200,200,200);
}

.cardBody{
    height:50px;
    width:120px;
    border-radius:5px;
    border:1px solid var(--color-green-medium);
    display:flex;
    flex-direction: row;
    margin-right:5px;
    margin-left:5px;
    margin-bottom:10px;
    box-shadow: 5px 2px 2px rgba(200,200,200,0.8);
}

.cardInfos{
    width:120px;
    height:100%;
    padding:5px
}

.cardEtiqueta{
    height:100%;
    width:30px;
    background-color: var(--color-green-medium);
    border-top-right-radius:4px;
    border-bottom-right-radius:4px;
    display:flex;
    align-items: center;
}

.retorno{
    height:30px;
    width:30px;
    border-radius:50px;
    border:1px solid var(--color-green-medium);
    position:relative;
    right:15px;
    background-color: white;
    color: var(--color-green-medium);
    font-size:10px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.ticker{
    color: var(--color-green-medium);
    font-size:10px;
    font-weight: bold;
}

.preco{
    font-size:9px;
    color:gray
}

.nclientes{
    font-size:9px;
    color:gray
}

.mensagem{
    color:var(--color-green-medium)
}

.tipo{
    font-size:10px;
    color:white;
    height:0px;
    width:0px;
    position:relative;
    right:10px;
    top:5px
}