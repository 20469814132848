.conjuntoInputsBox{
    border:1px solid var(--color-green-light);
    background-color:rgba(0,139,139,0.05);
    border-radius:5px;
    height:100px;
    padding:10px;
}

.conjuntoInputsTitulo{
    color:var(--color-green-light);
    font-weight: bold;
}

.paddingForm{
    padding-left:25px;
    padding-right:25px;
    margin-bottom:20px
}

.radio-line{
    border-top:1px solid var(--color-green-light);
    border-bottom:1px solid var(--color-green-light);
    padding-top:10px;
    padding-bottom:10px;
    padding-left:10px
}

.radio-title{
    color: var(--color-green-light);
    font-weight: bold;
}