.indiceBox{
    margin-top:15px;
    padding:10px;
    /*border: 1px solid rgba(255,255,0,.4);*/
    border-radius:5px;
    /*background-image:linear-gradient(to top right, rgba(255,255,0,.1) , rgba(255,255,0,.0) );*/
}

.tituloIndiceBox{
    width:100%;
    height:40px;
    border-radius:5px;
    font-weight: bold;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom:15px;
    padding-right: 10px;
    padding-left:10px
}

.indexTitulo{
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.indexGrafico{
    height:40px;
    cursor:pointer;
    display:flex;
    justify-content: end;
}

.corpoIndiceBox{
    height:200px;
    width:100%
}

.corpoIndiceBox .topInfos{
    height:50px;
    margin-top:5px;
    margin-bottom:5px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}

.corpoIndiceBox .topInfos .topInfo{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right:1px solid rgb(220,220,220);
    width:33.333333333%;
}

.corpoIndiceBox .topInfos .topInfo .topInfoTitulo{
    font-size: 10px;
    color:gray
}

.corpoIndiceBox .topInfos .topInfo .topInfoValor{
    font-size:20px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.infos{
    height:140px;
    padding-top:10px;
    padding-bottom:10px;
    margin-top:10px;
    border:1px solid rgb(200,200,200);
    border-radius:5px
}

.infoLine{
    height:30px;
    font-size:10px;
    border-bottom:1px solid rgb(220,220,220);
    display:flex;
    padding-right:10px;
    padding-left:10px;
    justify-content: space-between;
    align-items: center;
}

.infoLineDesc{
    color:gray
}

.ultimaLinha{
    border:0px solid gray !important
}