.form-control:focus{
    box-shadow: 0 0 0 0.25rem rgba(102,205,170,0.25);
    border-color: var(--color-green-light);

}

form{
    color:var(--color-gray);
}

form input{
    color:var(--color-gray) !important;
}

.btn-primary{
    background-color: var(--color-green-light);
    border: 1px solid rgba(0,0,0,0);
    box-shadow: 0.25em 0.25em 8px rgba(200,200,200,0.5);
}

.btn-primary:hover{
    background-color: var(--color-green-medium);
    border: 1px solid rgba(0,0,0,0);
}

.form-check-input:focus {
      border-color: var(--color-green-light);
      outline: 0;
     box-shadow: 0 0 0 0.25rem rgba(102,205,170, 0.25);
}

.form-check-input:checked {
  background-color: var(--color-green-medium);
  border-color: var(--color-green-light);
}

.input-group{
    width:auto;
}

.btn-fim-input{
    display:flex;
}


.cadastro{
    margin-bottom:50px;
}

.cadastro-bemvindo{
    color:var(--color-gray);
    font-size:20px;
}

.cadastro-bemvindo strong{
    font-size:25px;
    font-weight: bold;
    color:var(--color-green-medium);
}

.cadastro-bemvindo em{
    font-weight:bold;
    text-transform: uppercase;
}

.btn-outline-padrao {
  --bs-btn-color: var(--color-green-medium);
  --bs-btn-border-color: var(--color-green-medium);
  --bs-btn-hover-color: white;
  --bs-btn-hover-bg: var(--color-green-medium);
  --bs-btn-hover-border-color: var(--color-green-medium);
  --bs-btn-focus-shadow-rgb: 0, 139, 139;
  --bs-btn-active-color: white;
  --bs-btn-active-bg: var(--color-green-medium);
  --bs-btn-active-border-color: var(--color-green-medium);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-cadastro-box{
    display:flex;
    justify-content:flex-end;
}

.btn-cadastro{
    width:200px;
    max-width:100%;
    margin-top:50px;
}

.icon-title{
    margin-right:10px;
}