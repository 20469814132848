.overallbox{
    height:100%;
    width:100%;
    border:0px solid rgb(200,200,200);
    border-radius:10px;
    background-image:linear-gradient(to bottom right, rgba(102,205,170,0.2) , rgb(102,205,170,0.3) );
    display:flex;
    flex-direction:row;
    align-items:end;
    padding-left:10px
}

.imagebox{
    padding-left:40px;
    height:120%;
    display:flex;
    align-items: baseline;
}

.imgctn{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.textobox{
    height:100%;
    margin-left:20px;
    max-width:60%;
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.fullWidth{
    width:100%
}

.noWidth{
    width:0%
}

@media screen and (max-width: 991.9px){

    .imagebox{
        display:none
    }

    .textobox{
        height:100%;
        max-width:100%;
    }

    .overallbox{
        background-image:linear-gradient(to bottom right, rgba(102,205,170,0) , rgb(102,205,170,0) );
    }
}