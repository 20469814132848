.width100{
    width:100%
}

.width25{
    width:25%
}

.table-results{
    max-height:calc(400px - 57px);
    overflow-y: scroll;
    border:1px solid rgba(200,200,200,0.5);
    border-radius:5px;
    padding:5px
}

@media screen and (max-width: 768px){
    .table-results{
        padding:0px
    }
}