.cardAcaoBox{
    height:300px;
    border:1px solid rgb(220,220,220);
    border-radius:5px;
    margin-top:15px;
    padding:15px
}

.cardAcaoTitulo{
    display:flex;
    justify-content:space-between;
    border-bottom: 1px solid rgb(200,200,200)
}

.nomeAcao{
    color:var(--color-green-medium);
    font-size:20px;
    font-weight: bold;
}

.patrimonioAcao{
    color:var(--color-green-medium);
    font-size:20px;
}

.cardAcaoCorpo{
    height:150px;
    width:100%;
    margin-top:10px;
    display:flex
}

.cardAcaoCorpoGraf{
    height:calc(100% - 40px);
    width:100%;
    margin:0px
}

.graficoDistAcao{
    width:150px;
    height:100%;
}

.percentualCarteira{
    display:flex;
    justify-content: center;
    align-items:center;
    color: var(--color-green-medium);
    font-size:30px;
    width:100000px
}

.detalhesAcao{
    width:calc(100% - 150px);
    height:100%;
    padding-left:10px;
    margin-left:10px;
}

.detalhesAcaoLinha{
    border-bottom:1px solid rgba(220,220,220,1.0);
    display:flex;
    justify-content: space-between;
    align-items: center;
    font-size:12px;
    height:25%;
    color: gray;
    padding-right:5px;
    padding-left:5px
}

.tituloDetalhesAcaoLinha{
    font-weight: normal;
    max-width:50%;
    color: var(--color-green-medium)
}

.variacaoAcao{
    height:50px;
    margin-top:10px;
    width:100%;
    border:0px solid var(--color-green-medium);
    background-image:linear-gradient(to bottom, rgba(102,205,170,0.2) , rgba(0,139,139,0.2) );
    border-radius:5px;
    display:flex;
    justify-content: center;
    align-items:center;
    color: var(--color-green-medium);
    font-size:20px;
    font-weight: bold;
}

.variacaoPerncetual{
    font-weight:normal
}

.dataAtualizacaoAcao{
    width:100%;
    font-style: italic;
    margin-top:5px;
    display:flex;
    justify-content: flex-end;
    color:gray;
    font-size:10px
}

@media screen and (max-width: 400px){
    .graficoDistAcao{
        display:none
    }

    .detalhesAcao{
        width:100%;
        margin-left:0px;
        padding-left:0px
    }
}

@media screen and (max-width: 720px) and (min-width: 576px){
    .graficoDistAcao{
        display:none
    }

    .detalhesAcao{
        width:100%;
        margin-left:0px;
        padding-left:0px
    }
}

@media screen and (max-width: 1100px) and (min-width: 992px){
    .graficoDistAcao{
        display:none
    }

    .detalhesAcao{
        width:100%;
        margin-left:0px;
        padding-left:0px
    }
}
