.secao{
    height:500px;
    width:100%;
    display:flex;
    flex-direction:row;
    padding-left:40px;
    padding-right:40px;
    padding-bottom:50px;
    padding-top:50px
}

.capaQuemSomos{
    background-image: url("../../../public/imagens/ImgHomeBackground4.png");
    background-size: cover;
    background-position: center;
    border-bottom: 0px solid var(--color-green-light);
}

.textoQuemSomos{
    height:100%;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color:var(--color-green-dark);
    font-size:50px
}

.textoBox{
    text-align: center;
    width:100%
}

.highlightBanner{
    color: var(--color-green-medium);
    font-size: 70px;
    letter-spacing: .50938rem;
}

.highlightBanner em{
    font-weight:900
}

.highlight{
    color: var(--color-green-medium);
    font-size: 35px;
    letter-spacing: .50938rem;
}

.highlight em{
    font-weight:900
}

.highlightDark{
    color: var(--color-green-light);
    font-size: 30px;
    letter-spacing: .50938rem;
}

.highlightDark em{
    font-weight:900
}

.textoDetalhe{
    font-size:15px;
    text-align: justify;
}

.textoDetalheQuemSomos{
    font-size:20px;
}

.textoSymbols{
    font-size: 35px;
    color: var(--color-green-medium);
    margin:5px;
    margin-top:15px
}

.imagemQuemSomos{
    height:100%;
    width:0%;
    display:flex;
    justify-content: center;
    align-items:end;
    padding-left:50px
}

.imgctn{
    height: 500px;
    width:100%;
    position:relative;
    top:100px;
    left:40px;
    border-top-left-radius: 300px;
    border-bottom-left-radius:300px;
    background-image: url("../../../public/imagens/ImagemHome.png");
    background-size: cover;
    background-position: center;
    border-top: 20px solid var(--color-green-medium);
}

.secaoValores{
    background-image: linear-gradient(to bottom, rgba(102,205,170,0), rgba(0,139,139,0));
    display:flex;
    flex-direction: row;
    padding-top:90px;
    min-height:500px;
    height:auto
}

.textoValoresBox{
    width:400px;
    margin-right:50px
}

.textoValoresTitulo{
    color: gray;
    font-size:25px;
    margin-bottom: 20px;
}

.textoValoresTitulo em{
    color: var(--color-green-medium);
    font-weight: bold;

}

.textoValores{
    color:gray;
    font-size:17px;
    width:100%
}

.textoValores em{
    font-weight: bold;
    color: var(--color-green-medium);
}

.cardCotainerValores{
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top:30px
}

.valoresTransparencia{
    background-image: url("../../../public/imagens/transparencia.jpg");
}

.valoresResponsabilidade{
    background-image: url("../../../public/imagens/responsabilidade.jpg");
}

.valoresInovacao{
    background-image: url("../../../public/imagens/inovacao.jpg");
}

.cardValores{
    border:1px solid rgb(200,200,200);
    border-radius:10px;
    height:280px;
    width:30%;
    box-shadow: 5px 5px 5px rgba(200,200,200,0.5);
    background-color: white;
    display:flex;
    flex-direction: column;
}

.cardBodyValores{
    width:100%;
    font-size:12px;
    height:80px;
    padding:10px;
    color:gray
}

.cardHeaderValores{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height:120px;
    width:100%;
    margin-bottom:10px;
    background-size: cover;
    background-position: center;
}

.cardHeaderTitleValores{
    text-align: center;
    color:var(--color-green-medium);
    font-weight: bold;
    font-size:20px;
    margin-bottom:10px
}

.backgroundOpcoes{
    background-image: linear-gradient(to bottom, rgb(0,94,94), rgb(1, 52, 52));
}

.secaoOpcoes{
    margin-top:0px;
    padding-bottom:0px;
}

.secaoTecnologia{
    background-image: linear-gradient(to bottom, rgba(102,205,170,0), rgba(0,139,139,0));
    padding-right:0px
}

.carouselBox{
    height:100%;
    width:50%;
    padding:10px
}

.carouselCaptionFormat{
    color: white;
    font-size:10px
}

.carouselBody{
    background-color: rgba(0,0,0,0);
    height:380px;
    width:100%;
    border-radius:5px;
    padding:45px;
}

.carouselBodyGraph{
    height:200px;
}

.youtubeBox{
    width:100%;
    height:100%;
}

.textoOpcoes{
    height:100%;
    width:50%;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    text-align: justify;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color:white;
    font-size:30px
}

.textoTitulo{
    margin-bottom:20px
}

.textoDetalhe em{
    font-weight: bold;
}

.secaoOpcoesBiblioteca{
    display:flex;
    flex-direction: column;
    margin-top:0px;
    padding-right: 0px;
    padding-top:0px
}

.cardCotainerLivros{
    width:100%;
    overflow-x: scroll;
    display:flex;
    flex-direction: row;
    margin-top:30px;
    padding-bottom:20px;
}

.tituloBiblioteca{
    color:white;
    margin-bottom:0px;
    font-size:30px
}

.tituloBiblioteca em{
    color:var(--color-green-light);
}

.cardLivros{
    border:1px solid rgb(200,200,200);
    border-radius:10px;
    height:280px;
    width:400px;
    background-color: white;
    display:flex;
    flex-direction: row;
    margin-right:30px
}

.cardImgLivros{
    height:280px;
    width:200px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.imgCardLivro{
    height:280px;
    width:200px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right:2px solid var(--color-green-medium)
}

.cardContentLivros{
    margin-left:15px;
    margin-right:10px;
    width: 200px
}

.cardHeaderTitleLivros{
    font-weight: bold;
    color: var(--color-green-medium);
    height:80px;
    text-align: center;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-bottom:20px;
    border-bottom:1px solid var(--color-green-medium)
}

.cardBoddyLivros{
    height:170px;
    color:gray;
    font-size:12px
}

.secaoOpcoesBiblioteca ::-webkit-scrollbar {
    width:0px !important;
    height:5px !important;
}

.backgroundTecnologia{
    background-image: url("../../../public/imagens/ImgHomeBackground2.png");
    background-size: cover;
    background-position: center;
}

.secaoTecnologia{
    border:0px solid red;
    display:flex;
    flex-direction:column
}

.textoTecnologia{
    width:100%;
    height:80px;
    border:0px solid red;
    padding-top:20px;
    color:gray;
    padding-right:40px
}

.textoTecnologia em{
    font-weight: bold;
    color:var(--color-green-medium);
    font-size:20px;
}

.cardCotainer{
    height:100%;
    width:100%;
    overflow-x: scroll;
    padding-top:20px;
    display:flex;
    flex-direction: row;
}

.secaoTecnologia ::-webkit-scrollbar {
    height: 5px !important;
    width: 0px !important;
    border-radius:5px   
}

.card{
    border:1px solid rgb(200,200,200);
    border-radius:10px;
    height:280px;
    width:240px;
    margin-right:40px;
    box-shadow: 5px 5px 5px rgba(200,200,200,0.5);
    background-image: linear-gradient(to bottom left, rgba(102,205,170,1), rgba(0,139,139,1));
    display:flex;
    flex-direction: column;
    padding:10px
}

.cardHeaderTitle{
    text-align: center;
    color:white;
    font-weight: bold;
    font-size:20px;
    margin-bottom:10px
}

.cardHeader{
    border:1px solid white;
    border-radius: 10px;
    height:120px;
    width:220px;
    margin-bottom:10px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.cardBody{
    font-size:15px;
    height:120px;
    color: white
}

.textoSymbolsCard{
    font-size: 75px;
    color: white;
    margin:5px;
    margin-top:15px
}

.imgApp{
    max-width:750px;
    height:400px;
    display:flex;
    align-items:baseline
}

.textoApp{
    height:100%;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items:baseline;
    justify-content: center;
    color:gray;
    font-size:30px
}

.imgApp1{
    height:200px;
    width:200px;
    position:relative;
    top:-100px;
    border:1px solid var(--color-green-medium);
    border-radius:10px;
    background-color: white;
    box-shadow: 5px 5px 5px rgba(200,200,200,0.5);
    object-fit: cover;
}

.imgApp2{
    height:300px;
    width:300px;
    position:relative;
    top:100px;
    left:-100px;
    border:1px solid var(--color-green-medium);
    border-radius:10px;
    background-color: white;
    box-shadow: 5px 5px 5px rgba(200,200,200,0.5);
    object-fit: cover;
}

.imgApp3{
    height:250px;
    width:250px;
    position:relative;
    top:00px;
    left:-250px;
    border:1px solid var(--color-green-medium);
    border-radius:10px;
    background-color: white;
    box-shadow: 5px 5px 5px rgba(200,200,200,0.5);
    object-fit: cover;
}

.imgApp4{
    height:150px;
    width:150px;
    position:relative;
    top:60px;
    left:-280px;
    border:1px solid var(--color-green-medium);
    border-radius:10px;
    background-color: white;
    box-shadow: 5px 5px 5px rgba(200,200,200,0.5);
    object-fit: cover;
}

.textoBoxApp{
    display:flex;
    flex-direction: row;
    align-items: center;
    border-left:1px solid var(--color-green-medium);
    margin-top:10px
}

.symbolBoxApp{
    width:80px;
    margin-right:10px;
}

.secaoPerformance{
    margin-top:20px
}

.secaoNossaEquipe{
    width:100%;
    display:flex;
    flex-direction:column;
    padding-left:40px;
    padding-right:40px;
    padding-bottom:50px;
    padding-top:50px;
    background-image: url("../../../public/imagens/ImgHomeBackground.png");
    background-size: cover;
    background-position: center;
}

.textoBoxEquipe{
    font-size: 30px;
    color:gray;
    margin-bottom:20px
}

.membroEquipeCardBox{
    width:100%
}

.membroEquipeCard{
    display:flex;
    flex-direction: row;
    height:200px;
    border:1px solid var(--color-green-medium);
    background-image: linear-gradient(to bottom left, rgba(250, 250, 250,1), rgba(230,230,230,1));
    box-shadow: 5px 5px 5px rgba(200,200,200,0.5);
    border-radius:10px;
    width:100%;
    margin-left:20px;
    margin-right:20px;
    margin-bottom:20px;
    margin-top:10px;
    padding:10px
}

.membroEquipeCardFoto{
    height:180px;
    width: 200px;
    border:1px solid var(--color-green-light);
    border-radius:10px
}

.imgCardEquipe{
    width:100%;
    height:100%;
    object-fit: cover;
    border-radius:10px
}

.membroEquipeCardTexto{
    display:flex;
    flex-direction:column;
    height:180px;
    margin-left:10px;
    width:calc(100% - 210px);
    border-radius:10px
}

.membroEquipeCardTitulo{
    width:100%;
    height:30px;
    font-size: large;
    font-weight: bold;
    display:flex;
    align-items: center;
    border-bottom:0px solid var(--color-green-light);
    color: var(--color-green-medium)
}

.membroEquipeCardCargo{
    width:100%;
    height:30px;
    font-size: small;
    display:flex;
    align-items: center;
    color: var(--color-green-medium)
}

.membroEquipeCardDescricao{
    height:120px;
    width:100%;
    color:rgb(100,100,100);
    font-size: 12px;
}

.secaoContato{
    padding-left:40px;
    padding-right:40px;
    padding-bottom:50px;
    padding-top:50px;
    height:auto;
    background-color: var(--color-green-light);
    display:flex;
    flex-direction: column;
    padding-top:30px;
    color:var(--color-green-dark);
    border-top:1px solid var(--color-green-medium)
}

.contatoTitulo{
    font-weight: bold;
    font-size: 20px;
    margin-bottom:20px
}

.contatoBox{
    display:flex;
    flex-direction:row;
    align-items: top;
}

.contatoSymbol{
    font-weight: bold;
    width:50px
}

.contatoCategoria{
    width:100px;
    font-weight: bold;
}

.secaoLoginCadastro{
    display:none
}

@media screen and (max-width: 1200px){

    .imgApp4{
        display:none
    }

    .imgApp3{
        display:none
    }

    .membroEquipeCardFoto{
        width: 150px;
    }

    .membroEquipeCardTexto{
        width:calc(100% - 160px);
    }
  
}

@media screen and (max-width: 990px){

    .secao{
        min-height:500px;
        height:auto;
    }

    .capaQuemSomos{
        display:flex;
        flex-direction:row;
        justify-content: center;
        align-items: center;
    }

    .textoQuemSomos{
        width:100%
    }

    .imagemQuemSomos{
        display:none
    }

    .secaoValores{
        flex-direction: column;
    }

    .textoValoresBox{
        width:100%;
        margin-right:0px
    }

    .secaoOpcoes{
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;
    }

    .carouselBox{
        width:100%
    }

    .textoOpcoes{
        width:100%
    }

    .textoTecnologia{
        height:auto;
        margin-bottom:20px
    }

    .cardCotainer{
        height:320px;
        width:100%
    }

    .secaoApp{
        display:flex;
        flex-direction: column;
    }

    .imgApp{
        width:100%;
        margin-bottom:50px
    }

    .textoApp{
        width:100%
    }

    .imgApp4{
        display:flex
    }

    .imgApp3{
        display:flex
    }

    .membroEquipeCardFoto{
        width: 200px;
    }

    .membroEquipeCardTexto{
        width:calc(100% - 210px);
    }

    .secaoLoginCadastro{
        display:flex;
        justify-content: center;
        height:30px;
        background-color: rgba(102,205,170,0.2)
    }
  
}

@media screen and (max-width: 800px){

    .capaQuemSomos{
        border-bottom: 1px solid var(--color-green-light);
    }

    .textoQuemSomos{
        font-size:30px
    }

    .highlightBanner{
        font-size: 50px;
    }

    .imgApp4{
        display:none
    }

    .cardCotainerValores{
        width:100%;
        display:flex;
        flex-direction: column;
        margin-top:30px;
        align-items: center;
    }

    .cardValores{
        border:1px solid rgb(200,200,200);
        border-radius:10px;
        height:280px;
        width:100%;
        margin-bottom:15px;
        box-shadow: 5px 5px 5px rgba(200,200,200,0.5);
        background-color: white;
        display:flex;
        flex-direction: column;
    }

}

@media screen and (max-width: 680px){

    .secaoOpcoes{
        padding-left:10px;
        padding-right:10px
    }

    .textoOpcoes{
        padding-left:10px;
        padding-right:10px   
    }

    .carouselBody{
        padding:5px;
        height:300px;
        margin-bottom:20px
    }

    .imgApp1{
        display:none
    }

    .secaoNossaEquipe{
        padding-left:10px;
        padding-right:10px
    }

    .textoBoxEquipe{
        text-align: center;
    }

    .membroEquipeCardBox2{
        padding-left:0px !important
    }

    .membroEquipeCardFoto{
        width: 100px;
    }

    .membroEquipeCardTexto{
        width:calc(100% - 110px);
    }

    .membroEquipeCardDescricao{
        font-size: 11px;
    }

}