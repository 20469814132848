.lista-box{
    border: 1px solid rgba(200,200,200,0.5);
    border-radius:10px;
    height:200px;
    padding:10px;
    margin-bottom:10px;
    background-color:rgba(250,250,250,0.5);
}

.lista-box-fixed{
    position:fixed;
    top: 60px;
    left: 10px;
    width: 300px;
    z-index: 10;
    border:1px solid var(--color-green-medium)
}

.symbol{
    width:30px
}

.lista-box-scroll{
    height:100%;
    overflow-y: scroll;
}

.lista{
    padding:0px 0px 0px 0px;
    margin-right: 5px
}

.lista li{
    border-bottom:1px solid rgba(200,200,200,1);
    color: gray;
    height:40px;
    display:flex;
    align-items: center;
    cursor:pointer
}

.listaTitulo{
    border:1px solid var(--color-green-medium);
    background-image:linear-gradient(to bottom, rgb(102,205,170) , rgb(0,139,139) );
    border-radius:10px;
    display:flex;
    align-items: center;
    padding-left:10px;
    height:50px;
    color:white;
    margin-bottom:10px;
    margin-top:0px;
    padding-right:15px
}

.box-grafico-gestores{
    height:260px;
    margin-top:0px;
    border: 1px solid rgba(200,200,200,0.5);
    border-radius:5px;
}

.box-grafico-clientes{
    margin-top:10px;
    border: 1px solid rgba(200,200,200,0.5);
    border-radius:5px;
    height:400px;
}

.ajuste-margem{
    margin-right:0px !important;
    margin-left:0px !important
}

.grafico-padding{
    padding:10px 10px 10px 10px
}

.btn-reset{
    width:200px !important;
    max-width:100% !important;
    background-color: rgba(230,230,230,1);
    border:1px solid gray
}

.margin-left{
    margin-left:10px
}

.radio-title{
    margin-bottom:5px
}

.clienteSelected{
    background-image:linear-gradient(to top, rgba(102,205,170,0.3) , rgba(0,139,139,0.0) );
    border-bottom: 1px solid var(--color-green-medium) !important;
    border-radius:5px;
    color: var(--color-green-medium) !important;
    font-weight: bold;
    padding-left:10px;
    transition-duration: 500ms;
}