.performanceContainer{
    width:100%;
    padding-top:15px;
    border:1px solid rgba(200,200,200,0.5);
    border-radius:5px;
    margin-top:15px;
    padding-left:15px;
    padding-right:15px;
    padding-bottom:15px;
    box-shadow: 5px 10px 8px rgba(200,200,200,0.5);
}

.contratovazio{
    color:gray;
    font-weight: bold;
}

.bonus-resumo{
    color:gray;
    margin-top:0px
}

.bonus-resumo em{
    color:var(--color-green-medium);
    font-size:20px;
    font-weight: bold;
}

.performanceGraficoReceita{
    margin-top:5px;
    height:300px;
    margin-bottom:50px
}

.performanceBox{
    width:100%;
    display:flex;
    flex-direction:column;
    height:100px;
    border-bottom:1px solid rgb(200,200,200);
    margin-top:20px
}

.performanceClientName{
    margin-left:10px;
    margin-right:10px;
    color:var(--color-green-medium);
    display:flex;
    justify-content: space-between;
}

.performanceClientName .nome{
    font-weight: bold;
}

.performanceScope{
    width:100%;
    height:40px;
    background-color:rgba(200,200,200,0.5);
    border:1px solid gray;
    border-radius:40px;
    display:flex;
    flex-direction:row;
    align-items: center;
    padding-right:5px
}

.performanceBar{
    display:flex;
    justify-content: end;
    border-radius:30px;
    margin-left:5px
}

.mediaRealizado{
    height:30px;
    border-radius:30px;
    padding-right:20px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    color:white;

}

.performanceBarComplementar{
    width:60%;
    display:flex;
    justify-content: end;
}

.targetToGo{
    height:30px;
    border-radius:30px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    color:gray;
    font-weight: bold;
    margin-right:20px
}

.saldoACompensar{
    color:gray;
    margin-left:10px;
    font-size:12px
}

@media screen and (max-width: 576px){
    .performanceContainer{
        width:100%;
        padding-top:15px;
        border:0px solid rgba(200,200,200,0.5);
        border-radius:5px;
        margin-top:15px;
        padding-left:0px;
        padding-right:0px;
        padding-bottom:0px;
        box-shadow: 0px 0px 0px rgba(200,200,200,0.5);
    }

    .mediaRealizado{
        font-size:15px
    
    }

    .targetToGo{
        font-size:15px
    }


}