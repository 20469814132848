.main{
    min-height: calc(100vh - 100px);
    font-family: 'Arial'; /* Name your font family */
    font-weight: 400 !important;
}

.summary{
    width: 100%;
    margin-bottom:15px;
    display:flex;
    flex-direction: column;
}

.dateBox{
    margin-bottom:10px;
    color:rgb(120,120,120)
}

.dateValue{
    font-weight: bold;
    color: var(--color-green-medium)
}

.calendar{
    border-radius: 5px;
    border:1px solid var(--color-green-medium);
    font-size:10px;
    height:330px;
    width:330px !important;
    box-shadow: 5px 5px 5px rgba(200,200,200,0.5);
    margin-right:15px;
    padding:10px
}

.indicators{
    border-radius:5px;
    border:1px solid rgba(200,200,200,1);
    width:100%;
}

.carouselCaptionFormat{
    color: var(--color-green-medium);
    font-size:10px
}

.carouselBody{
    background-color: white;
    height:330px;
    border-radius:5px;
    padding-top:10px;
    padding-left:100px;
    padding-right:100px;
}

.carouselBodyGraph{
    height:200px;
}

@media screen and (max-width: 780px){

    .indicators{
        display:none
    }

    .calendar{
        width:100% !important;
        margin-right:0px
    }

}