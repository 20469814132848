/*NAVBAR*/

.navbar{
    background-image:linear-gradient(to bottom, rgb(102,205,170) , rgb(0,139,139) );
    padding-top:5px;
    padding-bottom:5px;
    height:50px
}

.nav-link{
    color:white;
    margin-left:10px;
}

.nav .active{
    text-decoration: underline;
    color:white !important;
    font-weight:bold;
}

#nav-collapse-button{
    border-color:white;
}

.navbarLinksHide{
    display:none !important;
}

.tituloPagina{
    display: none
}

@media screen and (max-width: 990px){

    .hide-app{
        display:none !important
    }

    .navbar{
        height:40px;
    }   

    .tituloPagina{
        color:white;
        display:flex;
        justify-content: center;
        width: 100%;
        height:100%;
    }

    .tituloPaginaTexto{
        font-size: 15px;
    }
  
}
