.painelGeral{
    min-height:400px;
    border:1px solid rgb(200,200,200);
    border-radius:5px;
    padding:10px
}

.painelTitulo{
    padding-left:0px;
    padding-right:0px;
    display:flex;
    flex-direction: row;
}

.painelCorpo{
    margin-top:10px;
    padding-left:0px;
    padding-right:0px;
}

.users .tituloPainel{
    color:var(--color-green-medium);
    font-weight: bold;
}

.users{
    width:15%
}

.macrobloco{
    border-left:1px solid rgb(200,200,200);
    width:23%
}

.tituloPainel{
    padding-bottom:10px;
    color: gray;
}

.tituloPrincipal{
    display:flex;
    justify-content: center;
    align-items: center;
    height:30px
}

.users .tituloPainel .tituloPrincipal{
    justify-content: flex-start;
}

.subtitulo{
    font-size: 10px;
    font-weight: normal;
    margin-top:5px;
    display:flex;
    flex-direction: row;
    height:20px;
    padding-left: 10px;
    padding-right: 10px;
}

.subtitulo .coluna{
    display:flex;
    align-items: center;
    justify-content: center;
}

.coluna{
    width:25%;
    text-align: center;
}

.corpoPainel{
    height:80%;
    color: gray;
    padding-left:10px;
    font-size:10px
}

.popoverTitulo{
    color:gray
}

.popoverCorpo{
    margin-bottom:5px;
    padding-bottom:5px;
    border-bottom:1px solid rgba(200,200,200,1.0);
    font-size: 12px;
    color:gray;
    display: flex;
    flex-direction: column;
}

.popoverDestaque{
    color:gray;
}

@media screen and (max-width: 990px){

    .users{
        width:25%
    }

    .bigView{
        display:none !important
    }

    .coluna{
        width:100%;
    }

    .macrobloco{
        width:33%
    }

}