/*HOME PAGE*/

h1{
    border-bottom:0px solid var(--color-green-light);
    color: var(--color-green-light);
    font-size:24px;
    margin-top:40px;
    margin-bottom:20px;
    display:flex;
    align-items:center;
    line-height:normal;
    font-weight:normal;
    padding:10px;
    background-color: rgba(102,205,170,0.0);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 10px 6px rgba(200,200,200,0.5);
}

.cadastro-bemvindo{
    height:200px;
    margin-bottom:10px;
    font-size:15px
}

.cadastro-bemvindo-login strong{
    color:var(--color-green-medium)
}

.cadastro-bemvindo-login{
    color:gray
}

.box-shadow{
    box-shadow: 5px 10px 8px rgba(200,200,200,0.5);
}

.sub-titulo-group{
    width:100%;
    display:flex;
    justify-content:space-between;
}

.pointer{
    cursor:pointer;
}

.hide{
    font-size:0px !important;
}

.hideBox{
    display:none
}

.icon-title{
    margin-right:10px;
}

.saldo-disponivel{
    border: 1px solid var(--color-green-light);
    border-radius:10px;
    color:rgb(0,139,139);
    margin-bottom:20px;
    height:120px;
}

.saldo-disponivel h3{
    text-align:center;
    margin-top:10px;
    margin-bottom:10px;
    font-weight:normal;
}

.saldo-disponivel p{
    text-align:center;
    margin-top:10px;
    margin-bottom:10px;
    font-weight:bold;
    font-size:30px;
}

.saldo-em-produtos{
    background-image:linear-gradient(to bottom, rgb(102,205,170) , rgb(0,139,139) );
    border: 1px solid rgba(0,139,139,1);
    border-radius:10px;
    color:white;
    margin-bottom:20px;
    height:120px;
}

.saldo-em-produtos h3{
    text-align:center;
    margin-top:10px;
    margin-bottom:10px;
    font-weight:normal;
}

.saldo-em-produtos p{
    text-align:center;
    margin-top:10px;
    margin-bottom:10px;
    font-weight:bold;
    font-size:30px;
}

.imagem-patrimonio{
    height:120px;
    border-radius:10px;
    margin-bottom:20px
}

.box-grafico{
    border: 1px solid var(--color-green-light);
    border-radius:5px;
    height:400px;
    width:100%;
}

.radioBoxPatrimonio{
    position:relative;
    margin-top:5px;
    margin-left:10px;
    z-index:2;
}

.form-check-label{
    color:gray;
    cursor:pointer;
    font-size:10px;
    position:relative;
    bottom:2px
}

.form-check-input{
    cursor:pointer
}

.box-grafico-projecao{
    border: 1px solid rgba(200,200,200,0.5);
    border-radius:5px;
    background-image:linear-gradient(to bottom right, rgba(200,200,200,0.5) , rgb(250,250,250,0.5) );
    padding:10px;
    box-sizing: border-box;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    height:50%;
}

.box-no-padding{
    padding:0px
}

.grafico-margin{
    margin:0px
}

.graficos-secundarios-projecao{
    display:flex;
    flex-direction:column;
}


.graficos-secundarios-projecao :nth-child(1){
    margin-top:0;
}

.boxGraficoTempo{
    width:100%;
    display:flex;
}

.graficoTempo{
    width:100%;
    height:40px;
    display:flex;
    align-items: center;
    color: var(--color-green-light)
}

.textoTempo{
    width:100px;
    color:gray;
    font-style: italic;
    font-size:12px;
    height:40px;
    display:flex;
    justify-content: center;
    align-items:center
}

.numeroTextoTempo{
    font-weight: bold;
    font-size: 25px;
    font-style: normal;

}

.boxRendimentoSempre{
    margin-top:10px;
    margin-bottom:10px;
    height:100px;
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}

.rendimentoSempre{
    padding:4px;
    width:47%;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    border-radius:5px;
    background-image:linear-gradient(to bottom, rgba(102,205,170,0.2) , rgba(0,139,139,0.2) );
}

.boxDuvidas{
    height:180px;
    background-color: rgba(200,200,200,0.3);
    border-radius:10px;
    display:flex;
    flex-direction:column;
    align-items: end;
}

.boxDuvidasTexto{
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    padding:20px;
    position:relative;
    top:20px;
    text-align: center;
    color:gray;
}

.boxDuvidasTexto span em{
    font-weight: bold;
    color: var(--color-green-medium)
}

.boxDecorativaDuvidas{
    height:70px;
    width:150px;
    position:relative;
    top:20px;
    right:20%;
    background-color:rgba(0,139,139,0.2);
    border-radius:10px;
}

.tituloRendimentoSempre{
    color: var(--color-green-medium);
    font-size:12px;
    text-align: center;
    height:50px;
}

.valorRendimentoSempre{
    height:60px;
    font-size:30px;
    color: var(--color-green-medium);
}

.rendimentoMedioMensal{
    padding:5px;
    width:47%;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    border-radius:5px;
    border:1px solid rgba(200,200,200)
}

.tituloRendimentoMedioMensal{
    color: gray;
    font-size:12px;
    text-align: center;
    height:50px;
}

.valorRendimentoMedioMensal{
    height:60px;
    font-size:30px;
    color: gray;
}

.rendimentoMedioMensal{
    width:47%;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    border-radius:5px;
}

.boxVolESharp{
    display:flex;
    flex-direction:row;
    width:100%;
    height:100%;
    justify-content: space-between;
    align-items: center;
    border:1px solid rgb(200,200,200);
    border-radius:5px;
}

.boxRentabilidade{
    border:1px solid rgb(200,200,200);
    border-radius:5px;
    padding:10px;
}

.iconRendimentoDoMes{
    height:140px;
    width:80%;
    display:flex;
    justify-content: center;
    align-items: center;
}

.imgRendimento{
    width:100% !important;
}

.sharp{
    width:50%;
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    background-image:linear-gradient(to top, rgba(102,205,170,0.2) , rgba(0,139,139,0.0) );
    padding-top:10px;
    padding-bottom:10px
}

.volESharp{
    width:50%;
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    padding-top:10px;
    padding-bottom:10px;
    background-image:linear-gradient(to top, rgba(150,150,150,0.2) , rgba(150,150,150,0.0) );
}

.tituloVolESharp{
    height:50px;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor:pointer
}

.tituloVolESharpTexto{
    color: gray;
    font-size:15px;
    text-align: center;
}


.valorVolatilidade{
    height:60px;
    font-size:30px;
    color: gray;
}

.infoVolESharp{
    display:flex;
    justify-content: end;
    width:100%;
    padding-right:10px;
}

.tituloSharp{
    width:100%;
    height:50px;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
}

.tituloSharpTexto{
    color: var(--color-green-medium);
    font-size:15px;
    text-align: center;
}

.tituloSharpIconLine{
    width:100%;
    display:flex;
    justify-content: end;
    align-items: top;
}

.tituloSharpIcon{
    height:30px;
    width:30px;
    margin-right: 10px;
    cursor:pointer
}

.tituloSharp span{
    margin-left:10px
}

.box-premios-acumulados{
    display:flex;
    flex-direction: row;
    padding:10px;
    height:150px
}

.box-direita-premios{
    display:flex;
    justify-content: center;
    align-items: center;
    height:100%;
    width:50%;
}

.box-esquerda-premios{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height:100%;
    width:50%;
    border:1px solid rgba(200,200,200,0.5);
    border-radius:5px
}

.titulo-de-premios-acumulados{
    color:gray;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height:50%
}

.valor-premios-acumulados{
    color:var(--color-green-medium);
    font-size: 30px;
    height:50%
}

.linhaDeIndices{
    margin-top:0px
}

.boxDistCarteira{
    border:1px solid rgb(220,220,220);
    border-radius:5px;
    display:flex;
    justify-content: center;
}

.boxDistCarteiraCorpo{
    width:100%;
    height:100%;
}

.boxGraficoCarteira{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:100%;
}

.graficoCarteira{
    height:300px;
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
}

.provisaoOpcoes{
    margin-bottom:10px;
    width:100%;
    color:gray;
    font-style: italic;
    font-size:13px
}

.boxLegendaCarteira{
    height:calc(100% - 20px);
    min-height:300px;
    padding:10px;
    margin:10px;
    border:0px solid gray;
    border-radius:10px
}

.linhaLegendaCarteira:last-child{
    border:0px solid white
}

.linhaLegendaCarteira{
    height:17%;
    width:100%;
    border-bottom:1px solid rgb(220,220,220);
    display:flex;
    align-items: center;
}

.corLegendaCarteira{
    height:30px;
    width:50px;
    border:1px solid white;
    border-radius:10px;
    margin-right: 20px;
    margin-left:20px;
    color:white;
    font-size:13px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.tituloLegendaCarteira{
    color: var(--color-green-light);
    font-weight: bold;
    font-size:15px;
    width:70%;
}

.valorLegendaCarteira{
    color:gray;
    font-size:15px;
    width:30%;
    display:flex;
    justify-content: right;
    padding-right:20px
}

.blocoAcoes{
    margin-top:20px;
}

.detalheAcoesTitulo{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    width:calc(100% - 40px);
}

.detalheAcoesBotoes{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height:100px;
}

.detalheAcaoStripe{
    width: calc(100%);
    height: 1px;
    border-radius:10px;
    margin-top:23px;
    background-image:linear-gradient(to left , rgba(102,205,170,0.5) , rgba(0,139,139,0) );
}

.detalheAcoesBotaoBox{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
}

.detalheAcoesBotaoTituloInfo{
    color:gray;
    font-size: 10px;
    text-align: center;
    transition: font-size 1s, color 1s
}

.detalheAcoesBotaoTituloGraf{
    color:gray;
    font-size: 10px;
    text-align: center;
    transition: font-size 1s, color 1s
}

.detalheAcoesBotaoTituloAtivo{
    color:var(--color-green-medium);
    font-weight: bold;
    font-size: 15px;
}

.detalheAcoesBotaoInfo{
    height:50px;
    width:50px;
    border:1px solid rgba(200,200,200,1);
    border-radius:5px;
    margin-right:0px;
    cursor:pointer;
    transition: width 1s, height 1s, border 1s
}

.detalheAcoesBotaoGraf{
    height:50px;
    width:50px;
    border:1px solid rgba(200,200,200,1);
    border-radius:5px;
    margin-left:10px;
    margin-right:10px;
    cursor:pointer;
    transition: width 1s, height 1s, border 1s
}

.box-grafico-carteira{
    border-radius:5px;
    border:1px solid rgba(200,200,200,1)
}

.moduloAtivo{
    height:70px;
    width:70px;
    border:1px solid var(--color-green-medium);
    box-shadow: 5px 10px 8px rgba(200,200,200,0.5);
    background-color: rgba(200,200,200,0.1)
}

table thead{
    background:linear-gradient(to bottom, rgba(102,205,170,1.0), rgba(0,170,170,1.0));
    border:0px solid rgba(0,170,170,1.0);
    border-radius:100px !important;

}

table td{
    color:var(--color-gray);
}

th{
    color:white;
}

.grafico-premios{
    height:400px
}

.box-consultor{
    min-height:200px;
    display:flex;
    flex-direction:column;
    justify-content:center;
}

.box-consultor p{
    color:var(--color-gray);
}

.box-consultor h4{
    color:var(--color-gray);
}

.box-consultor em{
    font-weight:bold;
}

.imagem-box-consultor{
    display:flex;
    align-items:center;
}

.imagem-consultor{
    height:200px;
    width:200px;
    border:3px solid var(--color-green-light); 
    border-radius:1000px;
    /*background-image:var(--imagem-consultor);*/
    background-repeat: no-repeat;
    background-position:center;
    background-size:cover;
}

.oculta{
    display:none !important
}

@media screen and (max-width: 992px){

    .graficos-secundarios-projecao{
        margin-top:10px;
        display:flex;
        flex-direction:row;
        justify-content: space-between;
    }

    .imagem-patrimonio{
        display:none
    }

    .boxDuvidas{
        display:none
    }

}


@media screen and (max-width: 768px){

    .box-grafico-carteira{
        height:400px;
        border-radius:5px;
    }

    .box-grafico-transparente{
        background-image:linear-gradient(to bottom right, rgba(200,200,200,0) , rgb(250,250,250,0) );
        border:0px solid white;
    }

    .box-shadow{
        box-shadow: 0px 0px 0px rgba(200,200,200,0.5);
    }

    .boxRentabilidade{
        margin-bottom:20px
    }

}

@media screen and (max-width: 576px){

    .graficos-secundarios-projecao{
        margin-top:30px;
        display:flex;
        flex-direction:column;
        justify-content: space-between;
    }

    .saldo-disponivel{
        margin-right:0px;
    }
    
    .saldo-em-produtos{
        margin-left:0px;
        margin-bottom:10px
    }

    .radioBoxPatrimonio{
        height:30px;
        position:relative;
        margin-top:0px;
        margin-left:10px;
        z-index:0;
    }

    .textoOlaExtendido{
        display:none
    }

    .cadastro-bemvindo{
        height:auto
    }

}
