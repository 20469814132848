.texto-politica{
    border-bottom: 1px solid var(--color-green-light);
    padding-bottom:20px;
    margin-bottom:30px
}

.btn-submit-termo{
    width:100%;
    max-width:400px;
    margin-top:50px	;
}

.btn-reset{
    width:100%;
    max-width:400px;
    margin-top:50px	;
    margin-right:0px !important;
    background-color: rgba(230,230,230,1);
    border:1px solid gray
}

.centralized{
    display: flex;
    align-items: center;
    justify-content:center
}

li::marker {
    content: '- ';
    font-size: 1.2em;
    font-weight:bold
}
