  .sidebar{
    font-size:13px;
    color:rgb(100,100,100);
    border-right: 1px solid rgb(181, 213, 234) !important;
  }
  
  .iconNav{
    color:var(--color-green-light);
    font-weight: bold;
  }

  .menuItem{
    background-color: rgb(240, 244, 246)
  }

  .menuItem :hover{
    background-color: rgb(217, 236, 229) !important
  }

  .menuItem .menuItem{
    color: rgb(150,150,150);
    background-color: rgb(243, 245, 245) !important
  }

  .hide{
    display:none !important
  }

  .collapse{
    width:100%;
    display:flex;
    justify-content: end;
    align-items:center;
    height:30px;
  }

  .collapseBottom{
    cursor:pointer
  }
  
  .sidebarheader{
    height:100px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .sidebarheadertech{
    font-size:30px;
    color: var(--color-green-medium);
    display:flex;
    justify-content: center;
    align-items: center;
  }

  .sidebarheaderuser{
    font-size:13px;
    color:gray;
    display:flex;
    justify-content: center;
    align-items: center;
  }
  
  .sidebarheader span em{
    font-weight: bold;
  }

  .sidebarsubtitulo{
    padding-left:20px;
    margin-top:15px;
    font-weight: bold;
    color:rgb(160,160,160);
    width:100%;
    height:30px;
    display:flex;
    align-items: center;
  }